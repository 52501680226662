const Enums = {
  ROUTES_TYPES: {
    COLLAPSE: 'collapse',
  },
  CURRENCY: {
    USD: 'USD',
  },
  DATE_FORMAT: {
    MM_DD_YYYY: 'MM-DD-YYYY',
    MM_DD_YYYY_HH_MM: 'MM-DD-YYYY hh:mm a',
  },
  USER_ROLE: {
    ADMIN: 'admin',
    SUPER_ADMIN: 'super_admin',
  },
  ALERT_STATUS: {
    RETRY: 'retry',
    FAILED: 'failed',
  },
};

export default Enums;
