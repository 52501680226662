import EndUrls from './endUrls';
import {
  postRequest,
  getRequest,
  patchRequest,
  putRequest,
  customRequest,
} from './apiServices';
import {userModel, usersModel} from './models/authModels';
import {clientModel} from './models/clientModels';
import {alertModel, alertCountModel} from './models/alertModels';
import {merchantModel} from './models/merchantModels';
import {commonModel} from './models/commonModels';
import {crmModel, merchantCrmModel} from './models/crmModels';
import configuration from '../../configs';
import StorageKeys from '../storage/storageKeys';
import {getLocalStorageItem} from '../storage/index';

// auth
export const userLoginApiCall = async requestBody => {
  const url = EndUrls.userLoginURL;
  const data = await postRequest(url, requestBody);
  return userModel(data);
};

export const forgotPasswordApiCall = async requestBody => {
  const url = EndUrls.forgotPassword;
  const data = await postRequest(url, requestBody);
  return commonModel(data);
};

export const resetPasswordApiCall = async requestBody => {
  const url = EndUrls.resetPassword;
  const data = await postRequest(url, requestBody);
  return commonModel(data);
};

// client
export const createClientApiCall = async requestBody => {
  const url = EndUrls.createClientURL;
  const data = await postRequest(url, requestBody);
  return commonModel(data);
};

export const fetchClientsApiCall = async (page, merchantName = '') => {
  const url = EndUrls.getClientsURL(page, merchantName);
  const data = await getRequest(url);
  return clientModel(data);
};

export const updateClientApiCall = async (id, requestBody) => {
  const url = EndUrls.updateClientURL(id);
  const data = await patchRequest(url, requestBody);
  return commonModel(data);
};

export const fetchClientsByNameApiCall = async clientName => {
  const url = EndUrls.getClientsByNameURL(clientName);
  const data = await getRequest(url);
  return clientModel(data);
};

// alert
export const fetchAlertsApiCall = async queryString => {
  const url = EndUrls.getAlertsURL(queryString);
  const data = await getRequest(url);
  return alertModel(data);
};

export const fetchAlertsCountApiCall = async queryString => {
  const url = EndUrls.getAlertsCountURL(queryString);
  const data = await getRequest(url);
  return alertCountModel(data);
};

export const retryAlertStatusApiCall = async id => {
  const tekenInfo = await getLocalStorageItem(StorageKeys.userSessionInfo);
  const accessToken = tekenInfo?.accessToken;
  const url = `${configuration.apiBpBaseUrl}${EndUrls.alertRetryURL(id)}`;

  const config = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    data: {},
  };

  const data = await customRequest(config);
  return commonModel(data);
};

// merchant
export const createMerchantApiCall = async requestBody => {
  const url = EndUrls.createMerchant;
  const data = await postRequest(url, requestBody);
  return commonModel(data);
};

export const fetchMerchantsApiCall = async queryString => {
  const url = EndUrls.getMerchant(queryString);
  const data = await getRequest(url);
  return merchantModel(data);
};

export const updateMerchantApiCall = async (id, requestBody) => {
  const url = EndUrls.updateMerchant(id);
  const data = await putRequest(url, requestBody);
  return commonModel(data);
};

// client crm
export const createClientCrmApiCall = async requestBody => {
  const url = EndUrls.createClientCrmURL;
  const data = await postRequest(url, requestBody);
  return commonModel(data);
};

export const fetchCrmCredentialFieldsApiCall = async provider => {
  const url = EndUrls.getCrmCredentialFieldsURL(provider);
  const data = await getRequest(url);
  return commonModel(data);
};

export const fetchClientCrmsApiCall = async queryString => {
  const url = EndUrls.getClientCrmsURL(queryString);
  const data = await getRequest(url);
  return crmModel(data);
};

export const updateClientCrmApiCall = async (id, requestBody) => {
  const url = EndUrls.updateClientCrmURL(id);
  const data = await putRequest(url, requestBody);
  return commonModel(data);
};

export const fetchMerchantCrmsApiCall = async merchantId => {
  try {
    const url = EndUrls.getMerchantCrmURL(merchantId);
    const data = await getRequest(url);
    return merchantCrmModel(data);
  } catch (error) {
    return error;
  }
};

export const updateMerchantCrmApiCall = async (merchantId, requestBody) => {
  const url = EndUrls.updateMerchantCrmURL(merchantId);
  const data = await patchRequest(url, requestBody);
  return commonModel(data);
};

export const validateCrmCredentialApiCall = async requestBody => {
  const url = EndUrls.validateCrmCredentialURL;
  const data = await postRequest(url, requestBody);
  return commonModel(data);
};

// user
export const fetchUsersApiCall = async queryString => {
  const url = EndUrls.getUsersURL(queryString);
  const data = await getRequest(url);
  return usersModel(data);
};

export const createUserApiCall = async requestBody => {
  const url = EndUrls.createUserURL;
  const data = await postRequest(url, requestBody);
  return commonModel(data);
};

export const updateUserApiCall = async (id, requestBody) => {
  const url = EndUrls.updateUserURL(id);
  const data = await patchRequest(url, requestBody);
  return commonModel(data);
};
