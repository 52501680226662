const Styles = {
  searchContainer: {
    mt: 3,
    mb: 3,
  },
  searchView: {
    display: 'flex',
    flexWrap: 'wrap',
    mt: 5,
    px: 6,
  },
  inputView: {
    width: 300,
  },
  searchBtnView: {
    display: 'flex',
    flexDirection: 'row',
    px: 5,
    mb: 3,
    mt: 0,
    justifyContent: 'right',
  },
  resetBtn: {
    mr: 2,
  },
  modalStyle: {
    marginTop: 50,
  },
  modalBodyContainer: {
    px: 2,
    pt: 3,
    pb: 3,
    display: 'flex',
    flexWrap: 'wrap',
  },
  infoLabelView: {
    display: 'flex',
    pr: 2,
    mb: 1,
  },
  infoLeftView: {
    mr: 5,
  },
  caseIdBtn: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    p: 0,
  },
  badgeView: {
    px: 2,
    borderRadius: 2,
  },
  retryBtn: {
    marginLeft: 5,
  },
  statusBox: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export default Styles;
